import {Authentication, validEmailRegex} from "./Authentication";
import {useCallbackState, useObserverState} from "../app/data/State.hooks";
import {convertAndUpdateFirebaseUser} from "./Authentication.service";
import {auth} from "../app/Firebase";
import firebase from "firebase";

const emailParamName = "emailForSignIn";

export const useSignIn = () =>
    useObserverState<Authentication | undefined>(
        (setSuccess, setError) =>
            auth.onAuthStateChanged(firebaseUser => {
                if (firebaseUser == null) {
                    setSuccess(undefined);
                } else {
                    window.history.replaceState(undefined, document.title, window.location.pathname);
                    convertAndUpdateFirebaseUser(firebaseUser).then(setSuccess).catch(setError);
                }
            }, setError),
        [],
    );

export const useSignOutAction = () => useCallbackState<void, void>(async () => await auth.signOut(), []);

export const useSendEmailLinkAction = () =>
    useCallbackState(async (email: string) => {
        if (!validEmailRegex.test(email)) {
            throw {code: "auth/email", message: "Email is not valid"} as firebase.FirebaseError;
        }
        window.localStorage.setItem(emailParamName, email);
        await auth.sendSignInLinkToEmail(email, {
            handleCodeInApp: true,
            url: window.location.href,
        });
    }, []);

export const useTestEmailLinkAction = () =>
    useCallbackState(async () => {
        if (!auth.isSignInWithEmailLink(window.location.href)) {
            return;
        }
        const email = window.localStorage.getItem(emailParamName);
        if (!email) {
            throw {
                code: "auth/email",
                message: "E-mail link must be opened in the same browser it was generated",
            } as firebase.FirebaseError;
        }
        const userCredential = await auth.signInWithEmailLink(email, window.location.href);
        window.localStorage.removeItem(emailParamName);
        return userCredential.user ?? undefined;
    }, []);
